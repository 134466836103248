import { notification } from "antd";
import { PurchaseOrderPositionRetrieve } from "./api";

export const DOMAIN = window.location.href.includes("localhost") ? "http://localhost:8000" : window.location.origin;

export enum CreateStepType {
  Form,
  Workflow,
}

export const getAccessToken = (): string => {
  return localStorage.getItem("access_token") ?? "";
};

export type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (type: NotificationType, successDescription?: string) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: successDescription,
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something went wrong.",
    });
  }
};

export const currencies = [
  {
    label: "KZT",
    value: "KZT",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "RMB",
    value: "RMB",
  },
  {
    label: "EURO",
    value: "EURO",
  },
  {
    label: "RUB",
    value: "RUB",
  },
];

export const paymentWorkflowSteps = ["Draft", "OnCoordination", "Coordinated", "PartialPaid", "Paid"];
export const blockPaymentStepEdit = ["Coordinated", "PartialPaid", "Paid"];

export const transformPositions = (orderPositions: Array<PurchaseOrderPositionRetrieve>) => {
  const positions: any[] = [];

  orderPositions.forEach((position) => {
    if (position.type === "Default") {
      positions.push({
        id: String(position.id),
        prPositionId: position.pr_positions[0].id,
        prProductName: position.pr_positions[0].product_name,
        prMeasureUnitName: position.pr_positions[0].measure_unit_name,
        prCostItemName: position.pr_positions[0].cost_item_name,
        prQuantity: position.pr_positions[0].quantity,
        poPositionId: position.id,
        poProductName: position.product_name,
        poMeasureUnitId: position.measure_unit,
        poQuantity: position.quantity,
        poPricePerItem: position.price,
        poTotalSum: position.sum,
        poCostItemId: position.cost_item,
        type: "Default",
      });
    } else if (position.type === "Complete") {
      position.pr_positions.forEach((prPosition: any) => {
        positions.push({
          id: String(position.id),
          prPositionId: prPosition.id,
          prProductName: prPosition.product_name,
          prMeasureUnitName: prPosition.measure_unit_name,
          prCostItemName: prPosition.cost_item_name,
          prQuantity: prPosition.quantity,
          poPositionId: position.id,
          poProductName: position.product_name,
          poMeasureUnitId: position.measure_unit,
          poQuantity: position.quantity,
          poPricePerItem: position.price,
          poTotalSum: position.sum,
          poCostItemId: position.cost_item,
          type: "Complete",
        });
      });
    } else if (position.type === "Unstack") {
      let prPosition = position.pr_positions[0];
      let poPositions: any[] = [];

      orderPositions.forEach((p: any) => {
        if (!p.isUsed && p.type === "Unstack" && prPosition.id === p.pr_positions[0].id) {
          poPositions.push(p);
          p.isUsed = true;
        }
      });

      poPositions.forEach((position: any) => {
        positions.push({
          id: String(position.id),
          prPositionId: prPosition.id,
          prProductName: prPosition.product_name,
          prMeasureUnitName: prPosition.measure_unit_name,
          prCostItemName: prPosition.cost_item_name,
          prQuantity: prPosition.quantity,
          poPositionId: position.id,
          poProductName: position.product_name,
          poMeasureUnitId: position.measure_unit,
          poQuantity: position.quantity,
          poPricePerItem: position.price,
          poTotalSum: position.sum,
          poCostItemId: position.cost_item,
          type: "Unstack",
        });
      });
    }
  });

  return positions;
};

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../../hooks";
import { ProtectedRouteProps } from "./types";

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, roles, ...rest }) => {
  const { user } = useUser();
  const location = useLocation();

  if (!user) {
    return (
      <Navigate
        to={"/login"}
        state={{ from: location }}
      />
    );
  }

  const hasAccess = roles ? roles.some((role) => user?.roles?.some((group: any) => group.code === role)) : true;

  if (!hasAccess) {
    return (
      <Navigate
        to="/403"
        state={{ from: location }}
      />
    );
  }

  return element;
};
